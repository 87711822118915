/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  width: 71.35416667%;
  max-width: 814px;
}
.cb-page-title {
  padding-bottom: 37px;
}
.cb-page-layout2 .maincontent {
  padding-top: 88px;
  padding-bottom: 88px;
}
.footer {
  padding-top: 124px;
  padding-bottom: 130px;
}
.vcard__address {
  width: 51.6%;
}
.opening-hours {
  float: left;
  width: 48%;
}
.reserve-desk {
  text-align: right;
  max-width: none !important;
  right: 64px;
  left: 64px;
  width: auto !important;
}
.sub1 {
  margin-top: 114px;
}
.cb-page-layout1 .main {
  width: 100%;
}
.cb-page-layout1 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout1 .main h2,
.cb-page-layout1 .main .foot {
  margin-right: 1.41843972%;
  margin-left: 1.41843972%;
}
.cb-page-layout1 .main .part,
.cb-page-layout1 .main > .grid table {
  margin-right: 1.41843972%;
  margin-left: 1.41843972%;
  width: 97.16312057%;
}
.cb-page-layout1 .main .tiny {
  width: 30.4964539%;
}
.cb-page-layout1 .main > .slim .part,
.cb-page-layout1 .main > .slim.grid table {
  width: 97.16312057%;
}
.cb-page-layout2 .area {
  width: 100%;
}
.cb-page-layout2 .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout2 .area h2,
.cb-page-layout2 .area .foot {
  margin-right: 17.15328467%;
  margin-left: 17.15328467%;
}
.cb-page-layout2 .area .part,
.cb-page-layout2 .area > .grid table {
  margin-right: 17.15328467%;
  margin-left: 17.15328467%;
  width: 65.69343066%;
}
.cb-page-layout2 .area > .slim .part,
.cb-page-layout2 .area > .slim.grid table {
  width: 65.69343066%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.cb-page-layout1 .main {
  width: 102.91970803%;
  margin-left: -1.45985401%;
}
.fold:before,
.fold:after,
.seam:before,
.seam:after {
  left: 1.45985401%;
  right: 1.45985401%;
}
.fold h2 > strong {
  display: inline-block;
}
.fold .ctrl {
  right: 1.45985401%;
}
.cb-page-layout2 .unit {
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 40px 0 50px;
}
.cb-page-layout2 div.link:last-child,
.cb-page-layout2 div.load:last-child {
  margin-bottom: -20px;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-medium.css.map */